
  <template v-slot:prepend>
  <v-btn-toggle
    v-model="value"
    dense
    borderless
    group
    color="primary"
    mandatory
  >
    <v-btn
      x-small
      value="users"
      @click="$emit('toggle-type-click')"
    >
      <icon-tooltip
        bottom
        small
        :open-delay="100"
        icon-name="i.AccountContacts"
        :tooltip-text="$t('t.Users')"
      />
    </v-btn>
    <v-btn
      x-small
      value="groups"
      @click="$emit('toggle-type-click')"
    >
      <icon-tooltip
        bottom
        small
        icon-name="i.Groups"
        :open-delay="100"
        :tooltip-text="$t('t.Groups')"
      />
    </v-btn>
    <v-btn
      x-small
      value="roles"
      @click="$emit('toggle-type-click')"
    >
      <icon-tooltip
        bottom
        small
        icon-name="i.Roles"
        :open-delay="100"
        :tooltip-text="$t('t.Roles')"
      />
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    value: {
      get () {
        return this.toggleValue
      },
      set (v) {
        this.$emit('update:toggle-value', v)
      }
    }
  },
  props: {
    toggleValue: String
  }
}
</script>
